$(() => {
  window.Geenie.ProjectsInvestment.get_promise().done(data => {
    const $collectedMoney = $('[data-investment-info-collected-money]');
    if (data.is_over_100million) {
      $collectedMoney.attr('class', 'stMoneyNum-million');
    } else {
      $collectedMoney.attr('class', 'stMoneyNum');
    }
    $.each(data.projects_returns, function () {
      const $linkToMain = $(`#return${this.id}`).find(
        '[data-investment-info-return-link-to-select-main]',
      );
      if (data.is_enabled && !this.is_soldout) {
        $linkToMain.addClass('is-enabled');
      }
    });
  });
});
